// Select component documentation : https://react-select.com/home

import React from "react"
import Select from "react-select"
import theme from "../../theme"

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: theme.white,
    fontFamily: "Lato",
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: "14px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontFamily: "Lato",
    textTransform: "uppercase",
    fontWeight: "700",
    fontSize: "14px",
  }),
}

export const StyledSelect = ({
  dataSelect,
  isDisabled,
  isLoading,
  isClearable,
  isRtl,
  isSearchable,
  name,
  isMulti,
  placeholder,
  value,
  handleChange,
}) => {
  return (
    <Select
      // defaultValue={dataSelect[0]}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={isRtl}
      isSearchable={isSearchable}
      name={name}
      options={dataSelect}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      styles={customStyles}
      theme={selectTheme => ({
        ...selectTheme,
        borderRadius: 0,
        colors: {
          ...selectTheme.colors,
          neutral0: theme.primary,
          neutral10: theme.white,
          neutral15: theme.white,
          neutral20: theme.white,
          neutral30: theme.white,
          neutral40: theme.white,
          neutral60: theme.white,
          neutral70: theme.white,
          neutral80: theme.white,
          neutral90: theme.white,
          primary25: theme.primary,
          primary60: theme.primary,
          primary75: theme.primary,
          primary: theme.primaryDark,
        },
      })}
    />
  )
}
