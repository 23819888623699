import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import FlecheBleu from "../../images/fleche.png"
import globalVariables from "../globalVariables"

const Item = styled.div`
  flex: 1 1 33%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  &:first-child {
    flex: 1 1 100%;
    position: relative;
    @media (min-width: ${globalVariables.minTablet}) {
      & > div::before {
        content: "";
        position: absolute;
        /* bottom: 0; */
        bottom: -60%;
        left: -50%;
        height: 60px;
        width: 55px;
        transform: scaleX(-1);

        background: url(${FlecheBleu});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
      & > div::after {
        content: "";
        position: absolute;
        /* bottom: 0; */
        bottom: -60%;
        right: -50%;
        height: 60px;
        width: 55px;

        background: url(${FlecheBleu});
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    @media (min-width: ${globalVariables.medDesktop}) {
      & > div::before,
      & > div::after {
        width: 75px;
      }
    }
  }
  p {
    margin-top: -1rem;
    max-width: 260px;
  }
`

const WrapperCircle = styled.div`
  height: 250px;
  width: 250px;
  position: relative;
  display: inline-block;
  @media (min-width: ${globalVariables.minTablet}) {
    height: 200px;
    width: 200px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    height: 280px;
    width: 280px;
  }
`

const Circle = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -15px;
  left: 0;
  height: 100%;
  width: 100%;
  @media (min-width: ${globalVariables.minTablet}) {
    top: -10px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    top: -15px;
  }
`

const WrapperImg = styled.div`
  height: 80px;
  width: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (min-width: ${globalVariables.minTablet}) {
    height: 55px;
    width: 55px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    height: 80px;
    width: 80px;
  }
`

const Title = styled.h3`
  margin-top: 10px;
  margin-bottom: 0;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 18px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    font-size: 21px;
  }
`

const ItemInfographie = ({ title, description, image, fullWidth, ellipse }) => {
  return (
    <Item fullWidth={fullWidth}>
      <WrapperCircle>
        <Img fluid={ellipse} />
        <Circle>
          <WrapperImg>
            <Img fluid={image} alt="le collectif engagés comment" />
          </WrapperImg>
          <Title>{title}</Title>
        </Circle>
      </WrapperCircle>
      <p>{description}</p>
    </Item>
  )
}

export default ItemInfographie
