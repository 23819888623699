import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

import Container from "../elements/Container"
import ItemInfographie from "./item"
import FlecheDore from "../../images/FlecheH.png"
import Dots from "../elements/DotsCarousel"

const WrapperItem = styled.div`
  padding: 1.5rem 0;
  .slick-dots {
    bottom: -50px !important;
  }
`

const WrapperCoeur = styled.div`
  width: 220px;
  height: 220px;
  position: relative;
  margin-top: 50px;
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  img {
    width: 100%;
    height: 100%;
  }
`

const WrapperFlecheDore = styled.div`
  width: 30px;
  height: 20px;
  position: absolute;
  top: ${props => props.top};
  bottom: ${props => props.bottom};
  left: ${props => props.left};
  right: ${props => props.right};
  transform: ${props => props.transform};
  background: url(${FlecheDore});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

const CoeurTexte = styled.div`
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  h4 {
    line-height: 1.4;
    margin: 0;
  }
`

const InfographieMobile = ({
  reseau,
  formation,
  visibilite,
  coeur,
  ellipse,
  comment,
}) => {
  const settings = {
    customPaging: function(i) {
      return <Dots />
    },
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  }

  return (
    <Container>
      {/* <WrapperCoeur>
        <Img fluid={coeur} />
        <WrapperFlecheDore
          top="unset"
          bottom="-5%"
          left="50%"
          right="unset"
          transform="translateX(-50%) rotate(180deg)"
        />
        <CoeurTexte>
          <h4>{comment.coeur[0].text}}</h4>
        </CoeurTexte>
      </WrapperCoeur> */}
      <WrapperItem>
        <Slider {...settings}>
          <ItemInfographie
            title={comment.titre1.text}
            description={comment.contenu1.text}
            image={comment.picto1.fluid}
            ellipse={ellipse}
          />
          <ItemInfographie
            title={comment.titre2.text}
            description={comment.contenu2.text}
            image={comment.picto2.fluid}
            ellipse={ellipse}
          />

          <ItemInfographie
            title={comment.titre3.text}
            description={comment.contenu3.text}
            image={comment.picto3.fluid}
            ellipse={ellipse}
          />
        </Slider>
      </WrapperItem>
    </Container>
  )
}

export default InfographieMobile
