import React, { useState } from "react"
import styled from "styled-components"
import axios from "axios"

import Container from "../elements/Container"
import Title from "../elements/Title"
import Input from "../elements/Input"
import Button from "../elements/Button"
import Anchor from "../elements/Anchor"
import globalVariables from "../globalVariables"
import { validator } from "./validationRulesEntreprise"
import { FormatIndentDecrease } from "styled-icons/material"

const WrapperBackground = styled.section`
  position: relative;
  padding-bottom: 3rem;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-color: ${props => props.theme.greyLighter};
    z-index: -1;
  }
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.white};
  border-bottom: 3px solid ${props => props.theme.secondary};
  box-shadow: 0px 10px 40px 5px rgba(171, 171, 171, 0.38);
  padding: 3rem 1rem 0 1rem;
  h4 {
    line-height: 1.4;
  }

  @media (max-width: ${globalVariables.maxMobile}) {
    .anchor-style {
      width: 50px;
      top: -25px;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 5rem 5rem 0rem 5rem;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    h4 {
      font-size: 20px;
    }
    .anchor-style {
      top: -32px;
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    .anchor-style {
      top: -39px;
    }
  }
`

const FormStyled = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  @media (min-width: ${globalVariables.medDesktop}) {
    padding-top: 2rem;
  }
`

const Spacer = styled.div`
  flex: ${props => (props.flex ? props.flex : "1 1 100%")};
  padding: 1rem 1rem;
  position: relative;
  button span {
    color: ${props => props.theme.primary};
  }
  text-align: ${props => (props.textAlign ? "center" : null)};
`

const ValidationMessage = styled.span`
  position: absolute;
  bottom: -11px;
  font-size: 90%;
  color: ${props => props.theme.error};
  opacity: ${props => (props.isInvalid ? "1" : "0")};
`

const SuccessMessage = styled.span`
  display: block;
  font-size: 90%;
  color: ${props => props.theme.secondary};
  opacity: ${props => (props.isSent ? "1" : "0")};
`

const InputSpam = styled.input`
  display: none;
`

const FormEntreprise = ({ data }) => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
    createdCompanyDate: "",
    isSent: false,
    validation: validator.valid(),
  }
  const [spamInput, setSpamInput] = useState("")

  const [formData, setFormData] = useState({
    ...initialState,
  })

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const updateSpamInput = event => {
    setSpamInput(event.target.value)
  }

  const updateInputData = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleSubmit = event => {
    event.preventDefault()

    const validation = validator.validate(formData)
    setFormData({
      ...formData,
      validation,
    })
    setIsFormSubmitted(true)

    if (validation.isValid && spamInput.length === 0) {
      const uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        )
      }
      const newFormData = { ...formData }
      newFormData.user_id = uuidv4()
      const date = new Date()
      const dateStr =
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      newFormData.date = dateStr

      axios
        .post(
          "https://pa3k5wjlqh.execute-api.eu-west-3.amazonaws.com/api/entreprises",
          {
            user_id: newFormData.user_id,
            date: newFormData.date,
            email: newFormData.email,
            firstName: newFormData.firstName,
            lastName: newFormData.lastName,
            companyName: newFormData.companyName,
          }
        )
        .then(result => {
          setFormData({ ...initialState, isSent: true })
        })

      // handle actual form submission here
    }
  }

  const { firstName, lastName, email, companyName, validation } = formData

  // if the form has been submitted at least once, then check validity every time we render
  // otherwise just use what's in state
  const copyFormData = { ...formData }

  const formValidation =
    isFormSubmitted && !formData.isSent
      ? validator.validate(copyFormData)
      : validation

  const isFormCompleted = () => {
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      companyName.length > 0
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <WrapperBackground id="form">
      <Container>
        <Wrapper>
          <Anchor anchor="form" position="top" />
          <Title as="h4">{data.titre_formulaire.text}</Title>
          <FormStyled onSubmit={handleSubmit}>
            <InputSpam
              type="text"
              value={spamInput}
              onChange={e => updateSpamInput(e)}
            />
            <Spacer flex="1 1 50%">
              <Input
                type="text"
                placeholder="Nom"
                value={lastName}
                handleChange={e => updateInputData(e)}
                name="lastName"
                required
                fullWidth
                id="lastName"
              />
              <ValidationMessage isInvalid={formValidation.lastName.isInvalid}>
                {formValidation.lastName.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Input
                type="text"
                placeholder="Prénom"
                value={firstName}
                handleChange={e => updateInputData(e)}
                name="firstName"
                required
                fullWidth
                id="firstName"
              />
              <ValidationMessage isInvalid={formValidation.firstName.isInvalid}>
                {formValidation.firstName.message}
              </ValidationMessage>
            </Spacer>
            <Spacer>
              <Input
                type="email"
                placeholder="Email"
                value={email}
                handleChange={e => updateInputData(e)}
                name="email"
                required
                fullWidth
                id="email"
              />
              <ValidationMessage isInvalid={formValidation.email.isInvalid}>
                {formValidation.email.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Input
                type="text"
                placeholder="Nom de société"
                value={companyName}
                handleChange={e => updateInputData(e)}
                name="companyName"
                required
                fullWidth
                id="companyName"
              />
              <ValidationMessage
                isInvalid={formValidation.companyName.isInvalid}
              >
                {formValidation.companyName.message}
              </ValidationMessage>
            </Spacer>
            <Spacer textAlign="center">
              <SuccessMessage isSent={formData.isSent}>
                Merci, votre message a bien été envoyé !
              </SuccessMessage>
              <Button
                title={data.bouton_formulaire.text}
                type="submit"
                colors="secondary"
                disabled={!isFormCompleted()}
              />
            </Spacer>
          </FormStyled>
        </Wrapper>
      </Container>
    </WrapperBackground>
  )
}

export default FormEntreprise
