import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import Section from "../elements/Section"
import Container from "../elements/Container"
import Button from "../elements/Button"
import globalVariables from "../globalVariables"
import Title from "../elements/Title"
import Anchor from "../elements/Anchor"
import InfographieDesktop from "./infographieDesktop"
import InfographieMobile from "./infographieMobile"
import { DeviceContext } from "../elements/DeviceProvider"

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const WrapperText = styled.div`
  margin-top: 50px;
  margin-bottom: 40px;
  p {
    max-width: 600px;
    text-align: center;
    @media (min-width: ${globalVariables.medDesktop}) {
      max-width: 900px;
    }
  }
`

const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  a {
    flex: 1 1 100%;
  }
  button {
    cursor: default;
    &:hover,
    &:focus,
    &:active {
      background: ${props => props.theme.secondary};
      border-color: ${props => props.theme.secondary};
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    a {
      flex: 1 1 auto;
    }
  }
`

const LeCollectif = ({
  title,
  text,
  comment,
  colorSection,
  coeur,
  ellipse,
}) => {
  return (
    <Section color={colorSection} id="le-collectif">
      <Anchor
        border="true"
        anchor="le-collectif"
        position="top"
        HiddenOnMobile
      />
      <Container>
        <WrapperContent>
          <Title as="h1">{title}</Title>
          <WrapperText>{Parser(text)}</WrapperText>
          <ButtonGroup>
            <Button title="Comment ?" colors="secondaryWithBg" />
          </ButtonGroup>
        </WrapperContent>
      </Container>
      <DeviceContext.Consumer>
        {deviceValues => {
          return deviceValues.width ? (
            deviceValues.isMobile ? (
              <InfographieMobile
                coeur={coeur}
                ellipse={ellipse}
                comment={comment}
              />
            ) : (
              <InfographieDesktop
                coeur={coeur}
                ellipse={ellipse}
                comment={comment}
              />
            )
          ) : null
        }}
      </DeviceContext.Consumer>
    </Section>
  )
}

export default LeCollectif
