import React from "react"
import styled from "styled-components"
import Parser from "html-react-parser"
import Img from "gatsby-image"

const Item = styled.div`
  flex: 1 1 33%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    text-align: center;
  }
`

const WrapperImage = styled.div`
  width: 90px;
  height: 90px;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .gatsby-image-wrapper {
    width: inherit;
  }
`

const Title = styled.h3``

const ReassuranceItem = ({ title, text, image }) => {
  return (
    <Item>
      <WrapperImage>
        <Img fluid={image} alt="icone de reassurance" />
      </WrapperImage>
      <Title as="h3">{title}</Title>
      {Parser(text)}
    </Item>
  )
}

export default ReassuranceItem
