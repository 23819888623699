import React from 'react';
import styled from "styled-components";

const Container = styled.div`
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
`;


const Wrapper = styled.div`
    padding: 8px;
    display: flex;
    box-sizing: border-box;
    svg {
        fill: ${props => props.theme.primary};
    }
`;

const Separator = styled.span`
    align-self: stretch;
    background-color: ${props => props.theme.primary};
    margin-bottom: 8px;
    margin-top: 8px;
    width: 1px;
    box-sizing: border-box;
    background: ${props => props.theme.primary};
`;

const CarretIcon = () => {
    return (
        <Container >
            <Separator />
            <Wrapper aria-hidden="true">
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false" >
                    <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z">
                    </path>
                </svg>
            </Wrapper>
        </Container>
    )
}

export default CarretIcon;
