import React, { useState } from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import globalVariables from "../globalVariables"
// import Button from "../elements/Button"
import { DeviceContext } from "../elements/DeviceProvider"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { getButtonStyled } from "../elements/Button/style"

const Wrapper = styled.div`
  flex: 0 0 50%;
  @media (min-width: ${globalVariables.minTablet}) {
    flex: 0 0 calc(100% / 3);
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    flex: 0 0 calc(100% / 5);
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    flex: 0 0 calc(100% / 6);
  }
`

const Item = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${props =>
    props.isInscriptionCard ? props.theme.primary : props.theme.white};
  border: 1px solid #e6e6e6;
  margin: 10px;
  padding: 0.5rem;
  position: relative;
  text-align: center;

  p {
    text-align: center;
    margin-bottom: 15px;
  }

  @media (min-width: ${globalVariables.minTablet}) {
    padding: 1rem;
  }
`

const WrapperImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${props => (props.isInscriptionCard ? "48px" : "100px")};
  height: ${props => (props.isInscriptionCard ? "auto" : "120px")};
  margin: ${props =>
    props.isInscriptionCard ? "20px auto 25px auto" : "20px auto 35px auto"};
  @media (min-width: ${globalVariables.minTablet}) {
    width: ${props => (props.isInscriptionCard ? "75px" : "150px")};
    height: ${props => (props.isInscriptionCard ? "auto" : "170px")};
    margin: ${props =>
      props.isInscriptionCard ? "30px auto 35px auto" : "30px auto 50px auto"};
  }
  .gatsby-image-wrapper {
    width: inherit;
  }
`

const Title = styled.h3`
  font-size: 18px;
  min-height: 40px;
  text-align: center;
  margin-bottom: 10px;
`
const Sector = styled.p`
  margin-bottom: 10px;
`
const TextInscription = styled.p`
  color: white;
  font-weight: 600;
  max-width: 155px;
  margin: auto;
  @media (min-width: ${globalVariables.minTablet}) {
    max-width: 140px;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    max-width: 155px;
  }
`
const Text = styled.a`
  transition: all 0.2s ease-in-out;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.primary};
  color: ${props => props.theme.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  opacity: ${props => (props.isMembreHover ? "1" : "0")};
`

// const Spacer = styled.div`
//   @media (min-width: ${globalVariables.minTablet}) {
//     margin: 1rem;
//   }
// `

const ButtonAnchor = styled(AnchorLink)`
  ${getButtonStyled} /* margin: 0; */
`

const WebsiteButton = styled.span`
  display: block;
  color: ${props => props.theme.white};
  border: 2px solid ${props => props.theme.secondary};
  padding: 5px 10px;
  cursor: pointer;
  font-family: Lato;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  margin-top: 1rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    background: ${props => props.theme.secondary};
    color: white;
  }
`

const CardMembre = ({
  title,
  sector,
  text,
  logo,
  isInscriptionCard,
  textInscription,
  buttonText,
  link,
}) => {
  const [isMembreHover, setIsMembreHover] = useState(false)

  const handleMouseEnter = () => {
    setIsMembreHover(true)
  }

  const handleMouseLeave = () => {
    setIsMembreHover(false)
  }

  const getOffset = windowWidth => {
    if (windowWidth < 768) {
      return 130
    } else if (windowWidth < 1441) {
      return 56
    } else {
      return 80
    }
  }
  return (
    <Wrapper>
      <Item
        isInscriptionCard={isInscriptionCard}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <WrapperImage isInscriptionCard={isInscriptionCard}>
          <Img fluid={logo} alt="Logo" />
        </WrapperImage>
        {sector && <Sector className="sector-style">{sector}</Sector>}
        {title && <Title as="h3">{title}</Title>}
        {isInscriptionCard && (
          <TextInscription>{textInscription}</TextInscription>
        )}
        {buttonText && (
          <DeviceContext.Consumer>
            {deviceValues => {
              return deviceValues.width ? (
                <ButtonAnchor
                  href="#form"
                  className="button-anchor"
                  colors="ternary"
                  offset={() => getOffset(deviceValues.windowWidth)}
                >
                  {buttonText}
                </ButtonAnchor>
              ) : null
            }}
          </DeviceContext.Consumer>
        )}
        {!isInscriptionCard && (
          <Text
            isMembreHover={isMembreHover}
            href={link ? link : "#"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {text}
            <WebsiteButton>Voir le site</WebsiteButton>
          </Text>
        )}
      </Item>
    </Wrapper>
  )
}

export default CardMembre
