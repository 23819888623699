import React, { useState } from "react"
import DayPicker from "react-day-picker"
import "react-day-picker/lib/style.css"

import { MONTHS } from "./SelectOptions"

const currentYear = new Date().getFullYear()
export const fromMonth = new Date(currentYear, 0)
export const toMonth = new Date(1920, 11)

const YearMonthForm = ({ date, dateSelect, handleMonthChange, handleYearChange }) => {

  const months = MONTHS

  const years = []
  for (let i = toMonth.getFullYear(); i <= fromMonth.getFullYear(); i += 1) {
    years.push(i)
  }


  return (
    <div className="DayPicker-Caption">
      <select
        name="month"
        onChange={event => handleMonthChange(event)}
        value={dateSelect.month}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        name="year"
        onChange={event => handleYearChange(event)}
        value={dateSelect.year}
      >
        {years.map(year => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div >
  )
}

export default YearMonthForm
