import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/Banner"
import LeCollectif from "../components/LeCollectif"
// import LesExperts from "../components/LesExperts"
import Inscription from "../components/Inscription"
import LesMembres from "../components/LesMembres"
import ContactNewsletter from "../components/ContactNewsletter"

const IndexPage = ({ data }) => {
  const prismicData = getPrismicData(data)

  return (
    <Layout
      logo={prismicData.logo}
      miniLogo={data.miniLogo.childImageSharp.fluid}
      modal={prismicData.modal}
      menu={prismicData.menu}
    >
      <SEO title="Le Collectif Engagés" />
      <Banner data={prismicData.banner} />
      <LeCollectif
        title={prismicData.collectif.titre.text}
        text={prismicData.collectif.contenu.html}
        buttonText={prismicData.collectif.buttonText.text}
        buttonText2={prismicData.collectif.buttonText2.text}
        comment={prismicData.collectif.comment}
        coeur={data.coeur.childImageSharp.fluid}
        ellipse={data.ellipse.childImageSharp.fluid}
      />
      {/* <LesExperts
        title={prismicData.experts.titre.text}
        text={prismicData.experts.contenu.html}
        data={prismicData.experts.items}
        imageExpertGauche={data.imageExpertGauche.childImageSharp.fluid}
        imageExpertDroite={data.imageExpertDroite.childImageSharp.fluid}
      /> */}
      <Inscription data={prismicData.inscription} />
      <LesMembres
        dataMembres={prismicData.membres.items}
        colorSection="greyLighter"
        title={prismicData.membres.titre.text}
        lastCardButton={prismicData.membres.lastCardButton.text}
        lastCardText={prismicData.membres.lastCardText.text}
        lastCardImage={prismicData.membres.lastCardImage.fluid}
      />
      <ContactNewsletter
        contact={prismicData.social}
        newsletter={prismicData.newsletter}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    prismicAccueil {
      data {
        body {
          ... on PrismicAccueilBodyLiensDuMenu {
            items {
              anchor {
                text
              }
              title {
                text
              }
            }
          }
        }
        logo_collectif_engages {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        body1 {
          ... on PrismicAccueilBody1Banniere {
            items {
              contenu_banniere {
                html
              }
              image_banniere {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              titre_banniere {
                text
              }
            }
          }
        }
        body2 {
          ... on PrismicAccueilBody2Comment {
            primary {
              contenu_centre_picto {
                text
                html
              }
              contenu_picto_1 {
                text
                html
              }
              contenu_picto_2 {
                html
                text
              }
              contenu_picto_3 {
                html
                text
              }
              image_picto_1 {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              image_picto_2 {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              image_picto_3 {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
              titre_picto_1 {
                text
              }
              titre_picto_2 {
                text
              }
              titre_picto_3 {
                text
              }
            }
          }
        }
        body3 {
          ... on PrismicAccueilBody3Expertise {
            items {
              nom {
                text
              }
              titre {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        body4 {
          ... on PrismicAccueilBody4Entreprise {
            primary {
              bouton {
                text
              }
              bouton_formulaire {
                text
              }
              contenu {
                html
                text
              }
              contenu_reassurance {
                html
                text
              }
              titre {
                text
              }
              titre_formulaire {
                text
              }
              titre_reassurance {
                text
              }
            }
            items {
              contenu {
                html
                text
              }
              titre {
                text
              }
              image_reassurance {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
          ... on PrismicAccueilBody4Entreprise1 {
            primary {
              bouton {
                text
              }
              bouton_formulaire {
                text
              }
              contenu {
                html
                text
              }
              contenu_reassurance {
                html
                text
              }
              titre {
                text
              }
              titre_formulaire {
                text
              }
              titre_reassurance {
                text
              }
            }
          }
        }
        body5 {
          ... on PrismicAccueilBody5Membres {
            primary {
              bouton {
                text
              }
              derniere_carte {
                text
              }
              derniere_carte_image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
            items {
              categorie {
                text
              }
              description {
                text
                html
              }
              nom {
                text
              }
              lien {
                url
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        body6 {
          ... on PrismicAccueilBody6Valeurs {
            items {
              contenu {
                html
                text
              }
              titre {
                text
              }
              image_reassurance {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        body7 {
          ... on PrismicAccueilBody7RevueDePresse {
            items {
              date {
                text
              }
              lien {
                url
              }
              media {
                text
              }
              image {
                fluid {
                  ...GatsbyPrismicImageFluid
                }
              }
            }
          }
        }
        titre_collectif {
          text
        }
        contenu_collectif {
          html
          text
        }
        texte_bouton_1 {
          text
        }
        texte_bouton_2 {
          text
        }
        titre_experts {
          text
        }
        contenu_experts {
          html
          text
        }
        titre_membres {
          text
        }
        titre_newsletter {
          text
        }
        contenu_newsletter {
          html
          text
        }
        bouton_newsletter {
          text
        }
        titre_social {
          text
        }
        contenu_social {
          html
          text
        }
        titre_valeurs {
          text
        }
        contenu_valeurs {
          html
          text
        }
        titre_histoire {
          text
        }
        contenu_histoire {
          html
          text
        }
        image_histoire {
          fluid {
            ...GatsbyPrismicImageFluid
          }
        }
        titre_equipe {
          text
        }
        contenu_equipe {
          html
          text
        }
        titre_mentions {
          text
        }
        contenu_mentions {
          html
          text
        }
        titre_protection {
          text
        }
        contenu_protection {
          html
          text
        }
        lien_instagram {
          url
        }
        lien_facebook {
          url
        }
        lien_linkedin {
          url
        }
        titre_revue_de_presse {
          text
        }
      }
    }
    miniLogo: file(relativePath: { eq: "mini-logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 45, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageExpertDroite: file(relativePath: { eq: "LesExpertsDroite.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageExpertGauche: file(relativePath: { eq: "LesExpertsGauche.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    coeur: file(relativePath: { eq: "coeur.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ellipse: file(relativePath: { eq: "ellipse.png" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const getPrismicData = data => {
  console.log("data", data)
  return {
    logo: data.prismicAccueil.data.logo_collectif_engages.fluid,
    menu: data.prismicAccueil.data.body[0].items,
    banner: data.prismicAccueil.data.body1[0].items,
    modal: {
      histoire: {
        titre: data.prismicAccueil.data.titre_histoire,
        contenu: data.prismicAccueil.data.contenu_histoire,
        image: data.prismicAccueil.data.image_histoire.fluid,
      },
      valeurs: {
        titre: data.prismicAccueil.data.titre_valeurs,
        contenu: data.prismicAccueil.data.contenu_valeurs,
        items: data.prismicAccueil.data.body6[0].items,
      },
      equipe: {
        titre: data.prismicAccueil.data.titre_equipe,
        contenu: data.prismicAccueil.data.contenu_equipe,
      },
      mentions: {
        titre: data.prismicAccueil.data.titre_mentions,
        contenu: data.prismicAccueil.data.contenu_mentions,
      },
      protection: {
        titre: data.prismicAccueil.data.titre_protection,
        contenu: data.prismicAccueil.data.contenu_protection,
      },
      presse: {
        titre: data.prismicAccueil.data.titre_revue_de_presse,
        items: data.prismicAccueil.data.body7[0].items,
      },
    },
    collectif: {
      titre: data.prismicAccueil.data.titre_collectif,
      contenu: data.prismicAccueil.data.contenu_collectif,
      // imagePresentation: {data.presentation.childImageSharp.fixed}
      buttonText: data.prismicAccueil.data.texte_bouton_1,
      buttonText2: data.prismicAccueil.data.texte_bouton_2,
      comment: {
        titre1: data.prismicAccueil.data.body2[0].primary.titre_picto_1,
        contenu1: data.prismicAccueil.data.body2[0].primary.contenu_picto_1,
        picto1: data.prismicAccueil.data.body2[0].primary.image_picto_1,
        titre2: data.prismicAccueil.data.body2[0].primary.titre_picto_2,
        contenu2: data.prismicAccueil.data.body2[0].primary.contenu_picto_2,
        picto2: data.prismicAccueil.data.body2[0].primary.image_picto_2,
        titre3: data.prismicAccueil.data.body2[0].primary.titre_picto_3,
        contenu3: data.prismicAccueil.data.body2[0].primary.contenu_picto_3,
        picto3: data.prismicAccueil.data.body2[0].primary.image_picto_3,
        coeur: data.prismicAccueil.data.body2[0].primary.contenu_centre_picto,
      },
    },
    experts: {
      titre: data.prismicAccueil.data.titre_experts,
      contenu: data.prismicAccueil.data.contenu_experts,
      items: data.prismicAccueil.data.body3[0].items,
    },
    inscription: {
      entreprise: {
        titre: data.prismicAccueil.data.body4[0].primary.titre,
        contenu: data.prismicAccueil.data.body4[0].primary.contenu,
        bouton: data.prismicAccueil.data.body4[0].primary.bouton,
        titre_reassurance:
          data.prismicAccueil.data.body4[0].primary.titre_reassurance,
        contenu_reassurance:
          data.prismicAccueil.data.body4[0].primary.contenu_reassurance,
        titre_formulaire:
          data.prismicAccueil.data.body4[0].primary.titre_formulaire,
        bouton_formulaire:
          data.prismicAccueil.data.body4[0].primary.bouton_formulaire,
        items: data.prismicAccueil.data.body4[0].items,
      },
      // particulier: {
      //   titre: data.prismicAccueil.data.body4[1].primary.titre,
      //   contenu: data.prismicAccueil.data.body4[1].primary.contenu,
      //   bouton: data.prismicAccueil.data.body4[1].primary.bouton,
      //   titre_reassurance:
      //     data.prismicAccueil.data.body4[1].primary.titre_reassurance,
      //   contenu_reassurance:
      //     data.prismicAccueil.data.body4[1].primary.contenu_reassurance,
      //   titre_formulaire:
      //     data.prismicAccueil.data.body4[1].primary.titre_formulaire,
      //   bouton_formulaire:
      //     data.prismicAccueil.data.body4[1].primary.bouton_formulaire,
      // },
    },
    membres: {
      titre: data.prismicAccueil.data.titre_membres,
      lastCardText: data.prismicAccueil.data.body5[0].primary.derniere_carte,
      lastCardImage:
        data.prismicAccueil.data.body5[0].primary.derniere_carte_image,
      lastCardButton: data.prismicAccueil.data.body5[0].primary.bouton,
      items: data.prismicAccueil.data.body5[0].items,
    },
    newsletter: {
      titre: data.prismicAccueil.data.titre_newsletter,
      contenu: data.prismicAccueil.data.contenu_newsletter,
      bouton: data.prismicAccueil.data.bouton_newsletter,
    },
    social: {
      titre: data.prismicAccueil.data.titre_social,
      contenu: data.prismicAccueil.data.contenu_social,
      lien_facebook: data.prismicAccueil.data.lien_facebook,
      lien_linkedin: data.prismicAccueil.data.lien_linkedin,
      lien_instagram: data.prismicAccueil.data.lien_instagram,
    },
  }
}
