import React, { useState } from "react"
import styled from "styled-components"
import DayPicker from "react-day-picker"
import "react-day-picker/lib/style.css"
import axios from "axios"

import Container from "../elements/Container"
import Title from "../elements/Title"
import Input from "../elements/Input"
import Button from "../elements/Button"
import Anchor from "../elements/Anchor"
import globalVariables from "../globalVariables"
import { validator } from "./validationRulesParticulier"
import { StyledSelect as Select } from "./Select"
import Checkbox from "../elements/Checkbox"
import CarretIcon from "./CarretIcon"
import {
  MONTHS,
  WEEKDAYS_LONG,
  WEEKDAYS_SHORT,
  maritalStatusOptions,
  childrenOptions,
  careerOptions,
  commitmentOptions,
  mailOptions,
} from "./SelectOptions"
import YearMonthForm, { fromMonth, toMonth } from "./YearMonthForm"

const WrapperBackground = styled.section`
  position: relative;
  padding-bottom: 3rem;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background-color: ${props => props.theme.greyLighter};
    z-index: -1;
  }
`

const Wrapper = styled.div`
  background-color: ${props => props.theme.white};
  border-bottom: 3px solid ${props => props.theme.secondary};
  box-shadow: 0px 10px 40px 5px rgba(171, 171, 171, 0.38);
  padding: 3rem 1rem 0 1rem;
  h4 {
    line-height: 1.4;
  }

  @media (max-width: ${globalVariables.maxMobile}) {
    .anchor-style {
      width: 50px;
      top: -25px;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 5rem 5rem 0rem 5rem;
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    h4 {
      font-size: 20px;
    }
    .anchor-style {
      top: -32px;
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    .anchor-style {
      top: -39px;
    }
  }
`

const FormStyled = styled.form`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
  @media (min-width: ${globalVariables.medDesktop}) {
    padding-top: 2rem;
  }
`

const Spacer = styled.div`
  flex: 1 1 100%;
  padding: ${props => (props.padding ? props.padding : "1rem 1rem")};
  position: relative;
  button span {
    color: ${props => props.theme.primary};
  }
  text-align: ${props => (props.textAlign ? "center" : null)};
  @media (min-width: ${globalVariables.minTablet}) {
    flex: ${props => (props.flex ? props.flex : "1 1 100%")};
  }
`

const Fieldset = styled.fieldset`
  border: none;
  margin-bottom: 0;
`
const Legend = styled.legend`
  color: ${props => props.theme.primary};
  font-weight: bold;
  margin-bottom: 10px;
`

const WrapperCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const InputChexkbox = styled.input`
  height: 29px;
  border: 1px solid ${props => props.theme.greyDarker};
  border-radius: 3px;
  outline: none;
  font-size: 16px;
  font-family: "SourceSerifPro";
  padding: 1px 10px;
  @media (min-width: ${globalVariables.minTablet}) {
    min-width: 350px;
  }
`

const ValidationMessage = styled.span`
  position: absolute;
  bottom: -11px;
  font-size: 90%;
  color: ${props => props.theme.error};
  opacity: ${props => (props.isInvalid ? "1" : "0")};
`

const SuccessMessage = styled.span`
  display: block;
  font-size: 90%;
  color: ${props => props.theme.secondary};
  opacity: ${props => (props.isSent ? "1" : "0")};
`

export const WrapperDate = styled.div`
  .DayPicker {
    position: absolute;
    top: 56px;
    left: 0;
    background: white;
    z-index: 1;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    /* width: 100%; */
    span,
    div {
      outline: none;
    }
  }
  .DayPicker-Day--today {
    color: ${props => props.theme.primary};
  }
  .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background: ${props => props.theme.primary};
  }
  .DayPicker-Day {
    width: 45px;
    height: 45px;
  }
`

export const WrapperDatePicker = styled.div`
  position: relative;
`

export const DateSelect = styled.div`
  border-bottom: 2px solid ${props => props.theme.primary};
  min-width: 175px;
  min-height: 40px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (min-width: ${globalVariables.minMobile}) {
    min-width: 195px;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    min-width: 215px;
  }
`

export const DateText = styled.span`
  padding: 5px 40px 5px 10px;
  display: flex;
  line-height: 20px;
  color: ${props => (props.isPlaceholder ? "#A2A2A2" : props.theme.black)};
  @media (min-width: ${globalVariables.minMobile}) {
    padding: 5px 40px 5px 25px;
  }
`

const InputSpam = styled.input`
  display: none;
`

const FormParticulier = ({ data }) => {
  const initialState = {
    firstName: "",
    lastName: "",
    email: "",
    postcode: "",
    date: null,
    maritalStatus: null,
    children: null,
    career: null,
    commitment: [],
    commitmentOther: "",
    survey: null,
    isNewsletterAgreed: false,
    isCollectifAgreed: false,
    isSent: false,
    validation: validator.valid(),
  }
  const [spamInput, setSpamInput] = useState("")
  const [formData, setFormData] = useState({
    ...initialState,
  })

  const [isFormSubmitted, setIsFormSubmitted] = useState(false)

  const updateSpamInput = event => {
    setSpamInput(event.target.value)
  }

  const updateInputData = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const updateSelect = (value, event) => {
    setFormData({
      ...formData,
      [event.name]: value,
    })
  }

  const handleCheckboxChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked,
    })
  }

  const handleCheckboxCommitment = event => {
    let newCommitmentState = []
    if (formData.commitment.includes(event.target.name)) {
      newCommitmentState = formData.commitment.filter(
        item => item !== event.target.name
      )
    } else {
      newCommitmentState = [...formData.commitment, event.target.name]
    }
    setFormData({
      ...formData,
      commitment: newCommitmentState,
    })
  }

  // handle Date Picker

  const [dateSelect, setDateSelect] = useState({
    month: formData.date ? formData.date.getMonth() : new Date().getMonth(),
    year: formData.date
      ? formData.date.getFullYear()
      : new Date().getFullYear(),
  })

  const handleMonthChange = event => {
    setDateSelect({
      ...dateSelect,
      month: event.target.value,
    })
    if (date) {
      setFormData({
        ...formData,
        date: new Date(dateSelect.year, event.target.value, date.getDate()),
      })
    }
  }

  const handleYearChange = event => {
    setDateSelect({
      ...dateSelect,
      year: event.target.value,
    })
    if (date) {
      setFormData({
        ...formData,
        date: new Date(event.target.value, dateSelect.month, date.getDate()),
      })
    }
  }

  const [isDayPickerOpen, setIsDayPickerOpen] = useState(false)

  const toggleDayPicker = () => {
    setIsDayPickerOpen(!isDayPickerOpen)
  }

  const handleDateChange = (date, { disabled }) => {
    if (disabled) {
      // Day is disabled, do nothing
      return
    }
    setFormData({
      ...formData,
      date: new Date(dateSelect.year, dateSelect.month, date.getDate()),
    })
    toggleDayPicker()
  }

  // end date picker

  const handleSubmit = event => {
    event.preventDefault()
    const copyFormData = { ...formData }
    const validation = validator.validate(copyFormData)
    setFormData({
      ...formData,
      validation,
    })
    setIsFormSubmitted(true)

    // si input antispam est rempli on n'envoit pas
    if (validation.isValid && spamInput.length === 0) {
      const uuidv4 = () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
          (
            c ^
            (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
          ).toString(16)
        )
      }
      const newFormData = { ...formData }
      newFormData.dateNaissance =
        newFormData.date.getDate() +
        "/" +
        (newFormData.date.getMonth() + 1) +
        "/" +
        newFormData.date.getFullYear()
      newFormData.user_id = uuidv4()
      const date = new Date()
      const dateStr =
        date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
      newFormData.date = dateStr
      const commitmentFiltered = newFormData.commitment.filter(
        word => word !== "Autre"
      )
      newFormData.commitment = commitmentFiltered
      if (!newFormData.commitmentOther) {
        newFormData.commitmentOther = "Non renseigné"
      }

      axios
        .post(
          "https://pa3k5wjlqh.execute-api.eu-west-3.amazonaws.com/api/particuliers",
          {
            user_id: newFormData.user_id,
            career: newFormData.career.value,
            children: newFormData.children.value,
            commitment: newFormData.commitment,
            commitmentOther: newFormData.commitmentOther,
            date: newFormData.date,
            dateNaissance: newFormData.dateNaissance,
            email: newFormData.email,
            firstName: newFormData.firstName,
            isNewsletterAgreed: newFormData.isNewsletterAgreed,
            isCollectifAgreed: newFormData.isCollectifAgreed,
            lastName: newFormData.lastName,
            maritalStatus: newFormData.maritalStatus.value,
            postcode: newFormData.postcode,
            survey: newFormData.survey.value,
          }
        )
        .then(result => {
          setFormData({ ...initialState, isSent: true })
        })

      // changer Autre si il y est dans commitment par le contenu de commitmentOther
      // handle actual form submission here
    }
  }

  const {
    firstName,
    lastName,
    email,
    postcode,
    date,
    maritalStatus,
    career,
    commitment,
    commitmentOther,
    survey,
    isNewsletterAgreed,
    isCollectifAgreed,
    children,
    validation,
  } = formData
  // if the form has been submitted at least once, then check validity every time we render
  // otherwise just use what's in state

  const copyFormData = { ...formData }
  const formValidation =
    isFormSubmitted && !formData.isSent
      ? validator.validate(copyFormData)
      : validation

  const isFormCompleted = () => {
    if (
      firstName.length > 0 &&
      lastName.length > 0 &&
      email.length > 0 &&
      postcode.length > 0 &&
      date !== null &&
      maritalStatus &&
      maritalStatus.value.length > 0 &&
      career &&
      career.value.length > 0 &&
      survey &&
      survey.value.length > 0 &&
      children &&
      children.value.length > 0 &&
      isNewsletterAgreed &&
      isCollectifAgreed &&
      commitment.length > 0
    ) {
      return true
    } else {
      return false
    }
  }

  return (
    <WrapperBackground id="form">
      <Container>
        <Wrapper>
          <Anchor anchor="form" position="top" />
          {data.titre_formulaire && data.titre_formulaire.text && (
            <Title as="h4">{data.titre_formulaire.text}</Title>
          )}
          <FormStyled onSubmit={handleSubmit}>
            <InputSpam
              type="text"
              value={spamInput}
              onChange={e => updateSpamInput(e)}
            />
            <Spacer flex="1 1 50%">
              <Input
                type="text"
                placeholder="Nom"
                value={lastName}
                handleChange={e => updateInputData(e)}
                name="lastName"
                required
                fullWidth
                id="lastName"
              />
              <ValidationMessage isInvalid={formValidation.lastName.isInvalid}>
                {formValidation.lastName.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Input
                type="text"
                placeholder="Prénom"
                value={firstName}
                handleChange={e => updateInputData(e)}
                name="firstName"
                required
                fullWidth
                id="firstName"
              />
              <ValidationMessage isInvalid={formValidation.firstName.isInvalid}>
                {formValidation.firstName.message}
              </ValidationMessage>
            </Spacer>
            <Spacer>
              <Input
                type="email"
                placeholder="Email"
                value={email}
                handleChange={e => updateInputData(e)}
                name="email"
                required
                fullWidth
                id="email"
              />
              <ValidationMessage isInvalid={formValidation.email.isInvalid}>
                {formValidation.email.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Input
                type="text"
                placeholder="Code postal"
                value={postcode}
                handleChange={e => updateInputData(e)}
                name="postcode"
                required
                fullWidth
                id="postcode"
              />
              <ValidationMessage isInvalid={formValidation.postcode.isInvalid}>
                {formValidation.postcode.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <WrapperDate>
                <WrapperDatePicker>
                  <DateSelect onClick={toggleDayPicker}>
                    <DateText isPlaceholder={formData.date ? false : true}>
                      {formData.date
                        ? formData.date.toLocaleDateString()
                        : "Date de naissance"}
                    </DateText>
                    <CarretIcon />
                  </DateSelect>
                  {isDayPickerOpen && (
                    <DayPicker
                      onDayClick={handleDateChange}
                      selectedDays={formData.date}
                      locale="fr"
                      months={MONTHS}
                      weekdaysLong={WEEKDAYS_LONG}
                      weekdaysShort={WEEKDAYS_SHORT}
                      month={formData.date}
                      firstDayOfWeek={1}
                      fromMonth={fromMonth}
                      toMonth={toMonth}
                      captionElement={({ date, localeUtils }) => (
                        <YearMonthForm
                          date={formData.date ? formData.date : date}
                          localeUtils={localeUtils}
                          handleMonthChange={handleMonthChange}
                          handleYearChange={handleYearChange}
                          dateSelect={dateSelect}
                        />
                      )}
                    />
                  )}
                </WrapperDatePicker>
              </WrapperDate>
              <ValidationMessage isInvalid={formValidation.date.isInvalid}>
                {formValidation.date.message}
              </ValidationMessage>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Select
                dataSelect={maritalStatusOptions}
                placeholder="Situation maritale"
                value={maritalStatus}
                handleChange={(value, event) => updateSelect(value, event)}
                name="maritalStatus"
                required
                fullWidth
                className="react-select"
                isSearchable={false}
              />
            </Spacer>
            <Spacer flex="1 1 50%">
              <Select
                dataSelect={childrenOptions}
                placeholder="Nombre d'enfants"
                value={children}
                handleChange={(value, event) => updateSelect(value, event)}
                name="children"
                required
                fullWidth
                isSearchable={false}
              />
            </Spacer>
            <Spacer flex="1 1 50%">
              <Select
                dataSelect={careerOptions}
                placeholder="Situation professionnelle"
                value={career}
                handleChange={(value, event) => updateSelect(value, event)}
                name="career"
                required
                fullWidth
                isSearchable={false}
              />
            </Spacer>

            <Spacer flex="1 1 100%">
              <Fieldset>
                <Legend>Type d'engagement recherché :</Legend>
                {commitmentOptions.map((item, index) => (
                  <WrapperCheckbox key={index}>
                    <Checkbox
                      key={index}
                      name={item.value}
                      checked={commitment.includes(item.value)}
                      onChange={handleCheckboxCommitment}
                      label={item.value}
                    />
                    {item.value === "Autre" ? (
                      <InputChexkbox
                        type="text"
                        placeholder="Champs libre"
                        value={commitmentOther}
                        onChange={e => updateInputData(e)}
                        name="commitmentOther"
                      />
                    ) : null}
                  </WrapperCheckbox>
                ))}
              </Fieldset>
            </Spacer>
            <Spacer flex="1 1 50%">
              <Select
                dataSelect={mailOptions}
                placeholder="J'accepte de recevoir"
                value={survey}
                handleChange={(value, event) => updateSelect(value, event)}
                name="survey"
                required
                fullWidth
                isSearchable={false}
              />
            </Spacer>
            <Spacer flex="1 1 100%" padding="2px 1rem">
              <Checkbox
                name="isNewsletterAgreed"
                checked={isNewsletterAgreed}
                onChange={handleCheckboxChange}
                label="J'accepte de recevoir des newsletters"
              />
            </Spacer>
            <Spacer flex="1 1 100%" padding="2px 1rem">
              <Checkbox
                name="isCollectifAgreed"
                checked={isCollectifAgreed}
                onChange={handleCheckboxChange}
                label="J'accepte d'intégrer le panel du collectif Engagés pour faire bouger les choses à mon échelle"
              />
            </Spacer>
            <Spacer textAlign="center">
              <SuccessMessage isSent={formData.isSent}>
                Merci, votre message a bien été envoyé !
              </SuccessMessage>
              <Button
                title={data.bouton_formulaire.text}
                type="submit"
                colors="secondary"
                disabled={!isFormCompleted()}
              />
            </Spacer>
          </FormStyled>
        </Wrapper>
      </Container>
    </WrapperBackground>
  )
}

export default FormParticulier
