import FormValidator from "../elements/FormValidator"

export const validator = new FormValidator([
  {
    field: "firstName",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre prénom",
  },
  {
    field: "lastName",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre nom",
  },
  {
    field: "email",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre e-mail",
  },
  {
    field: "email",
    method: "isEmail",
    validWhen: true,
    message: "Cet e-mail n'est pas valide",
  },
  {
    field: "postcode",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre code postal",
  },
  {
    field: "postcode",
    method: "matches",
    args: [/^(?:[0-8]\d|9[0-8])\d{3}$/], // args is an optional array of arguements that will be passed to the validation method
    validWhen: true,
    message: "Ce code postal n'est pas valide",
  },
  {
    field: "date",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre date de naissance",
  },
])
