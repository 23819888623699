import React from "react"
import styled from "styled-components"

import Anchor from "../elements/Anchor"
import globalVariables from "../globalVariables"
import { DeviceContext } from "../elements/DeviceProvider"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { getButtonStyled } from "../elements/Button/style"

const Title = styled.h2`
  position: relative;
  color: ${props => props.theme.white};

  text-align: center;
  line-height: 1.3;
  margin-bottom: 0;
  @media (max-width: ${globalVariables.maxMobile}) {
    font-size: 18px;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 1.45rem;
    &::after {
      content: "";
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translateX(-50%);
      height: 4px;
      width: 44px;
      background: ${props => props.theme.white};
    }
  }
`

const Wrapper = styled.div`
  background: ${props =>
    props.isActive ? props.theme.primaryDark : props.theme.primary};
  border-bottom: 3px solid
    ${props => (props.isActive ? props.theme.secondary : props.theme.white)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  position: relative;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  /* &:hover {
    background: ${props => props.theme.primaryDark};
    border-bottom: 3px solid ${props => props.theme.secondary};
  } */
  p {
    font-weight: 600;
    color: white;
    margin-top: 3rem;
    max-width: 315px;
    text-align: center;
    @media (min-width: ${globalVariables.minTablet}) {
      max-width: 400px;
    }
    @media (min-width: ${globalVariables.medDesktop}) {
      max-width: 400px;
    }
  }
  /* Only on mobile */
  @media (max-width: ${globalVariables.maxMobile}) {
    .anchor-style {
      width: 35px;
      height: 35px;
      bottom: -20px;
    }
  }
  @media (min-width: ${globalVariables.minTablet}) {
    .anchor-style {
      bottom: 30px;
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    .anchor-style {
      bottom: 37px;
    }
  }
`

const ButtonAnchor = styled(AnchorLink)`
  ${getButtonStyled}
`

const CallToAction = ({
  title,
  description,
  buttonText,
  isActive,
  colorBg,
  handleClick,
}) => {
  const getOffset = windowWidth => {
    if (windowWidth < 768) {
      return 130
    } else if (windowWidth < 1441) {
      return 56
    } else {
      return 80
    }
  }

  return (
    <Wrapper isActive={isActive} colorBg={colorBg} onMouseEnter={handleClick}>
      <Title>{title}</Title>
      <DeviceContext.Consumer>
        {deviceValues => {
          return deviceValues.width
            ? !deviceValues.isMobile && (
                <React.Fragment>
                  <p>{description}</p>
                  <ButtonAnchor
                    href="#form"
                    className="button-anchor"
                    colors={isActive ? "secondaryWithBg" : "ternary"}
                    offset={() => getOffset(deviceValues.windowWidth)}
                  >
                    {buttonText}
                  </ButtonAnchor>
                </React.Fragment>
              )
            : null
        }}
      </DeviceContext.Consumer>
      {isActive && <Anchor anchor="reassurance" position="bottom" />}
    </Wrapper>
  )
}

export default CallToAction
