import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 12px;
`

const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  display: flex;
`
// Hide checkbox visually but remain accessible to screen readers.
// Source: https://polished.js.org/docs/#hidevisually
const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  flex-shrink: 0;
  display: inline-block;
  width: 16px;
  height: 16px;
  background: ${props =>
    props.checked ? props.theme.primary : props.theme.greyLight};
  border-radius: 3px;
  transition: all 150ms;
  margin-top: 4px;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-top: 0;
  }

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px
      ${props => (props.checked ? props.theme.primaryLight : props.theme.white)};
  }

  ${Icon} {
    visibility: ${props => (props.checked ? "visible" : "hidden")};
  }
`

const LabelWrapper = styled.span`
  margin-left: 8px;
`

const Label = styled.label`
  display: flex;
  align-items: flex-start;
  @media (min-width: ${globalVariables.minTablet}) {
    align-items: center;
  }
`

const Checkbox = ({ name, checked, label, ...props }) => (
  <CheckboxContainer>
    <Label>
      <HiddenCheckbox checked={checked} name={name} {...props} />
      <StyledCheckbox checked={checked}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
      <LabelWrapper>{label}</LabelWrapper>
    </Label>
  </CheckboxContainer>
)

export default Checkbox
