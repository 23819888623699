import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Parser from "html-react-parser"
// import { RichText } from "prismic-reactjs"

import Section from "../elements/Section"
import globalVariables from "../globalVariables"
import Dots from "../elements/DotsCarousel"

const WrapperSlider = styled.div`
  .gatsby-image-wrapper {
    height: 350px;
    @media (min-width: ${globalVariables.minTablet}) {
      height: 500px;
    }
    @media (min-width: ${globalVariables.medDesktop}) {
      height: 780px;
    }
  }
`

const H2 = styled.h2`
  color: ${props => props.theme.primaryLight};
  font-size: 26px;
  @media (min-width: ${globalVariables.minTablet}) {
    font-size: 32px;
  }
`
const Item = styled.div`
  width: 100%;
  position: relative;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
`

const Content = styled.div`
  background-color: ${props => props.theme.white80};
  border: 3px solid ${props => props.theme.white};
  padding: 60px 20px 30px 20px;
  margin: auto;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  @media (min-width: ${globalVariables.minTablet}) {
    height: unset;
    min-height: 340px;
    width: 700px;
    padding: 0 60px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    padding: 90px 60px 70px 60px;
    max-width: 700px;
  }
  p {
    margin-bottom: 2rem;
    @media (min-width: ${globalVariables.minTablet}) {
      font-size: 18px;
    }
  }
`

const Banner = ({ data }) => {
  const settings = {
    customPaging: function(i) {
      return <Dots />
    },
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Section padding="0" id="banner">
      <WrapperSlider>
        <Slider {...settings}>
          {data.map((item, index) => (
            <Item key={index}>
              <Img fluid={item.image_banniere.fluid} />
              <Wrapper>
                <Content>
                  <H2>{item.titre_banniere.text}</H2>
                  {item.contenu_banniere && Parser(item.contenu_banniere.html)}
                </Content>
              </Wrapper>
            </Item>
          ))}
        </Slider>
      </WrapperSlider>
    </Section>
  )
}

export default Banner
