import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Parser from "html-react-parser"

import globalVariables from "../globalVariables"
import Section from "../elements/Section"
import Container from "../elements/Container"
import Title from "../elements/Title"
import ReassuranceItem from "./ReassuranceItem"
import Dots from "../elements/DotsCarousel"

const WrapperContent = styled.div`
  flex: 1 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const WrapperText = styled.div`
  margin-top: 50px;
  margin-bottom: 40px;
  max-width: 700px;
  p {
    margin-bottom: 10px;
  }

  @media (min-width: ${globalVariables.medDesktop}) {
    max-width: 900px;
    margin-bottom: 60px;
  }
`

const ReassuranceBloc = ({ id, title, text, items }) => {
  const settings = {
    customPaging: function(i) {
      return <Dots />
    },
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <Section id={id} padding="100px 0 50px">
      <Container>
        <WrapperContent>
          <Title as="h2">{title}</Title>
          {text && <WrapperText>{Parser(text)}</WrapperText>}
        </WrapperContent>
        {items && (
          <Slider {...settings}>
            {items.map((item, index) => (
              <ReassuranceItem
                title={item.titre.text}
                text={item.contenu.html}
                image={item.image_reassurance.fluid}
                key={index}
              />
            ))}
          </Slider>
        )}
      </Container>
    </Section>
  )
}

export default ReassuranceBloc
