import React, { useState } from "react"
import styled from "styled-components"
import Parser from "html-react-parser"

import Section from "../elements/Section"
import Container from "../elements/Container"
import Title from "../elements/Title"
import globalVariables from "../globalVariables"
import CardMembre from "./CardMembre"
import { StyledSelect as Select } from "../elements/Select"
import { DeviceContext } from "../elements/DeviceProvider"

const WrapperContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
`

const Filtre = styled.ul`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 2rem;
`

const Category = styled.li`
  list-style: none;
  transition: all 0.2s ease-in-out;
  padding: 5px 15px;
  margin: 1rem;
  cursor: pointer;
  &.isActive,
  &:hover {
    background-color: ${props => props.theme.primary};
    color: ${props => props.theme.white};
  }
`

const WrapperItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  @media (min-width: ${globalVariables.minTablet}) {
    max-width: 630px;
    margin: auto;
  }

  @media (min-width: ${globalVariables.minDesktop}) {
    max-width: 1220px;
  }

  @media (min-width: ${globalVariables.medDesktop}) {
    max-width: 1440px;
  }
`

const WrapperSelect = styled.div`
  padding: 0 10px 30px 10px;
  width: 100%;
`

const LesMembres = ({
  dataMembres,
  colorSection,
  title,
  lastCardImage,
  lastCardButton,
  lastCardText,
}) => {
  const [category, setCategory] = useState("all")

  const handleClick = value => {
    setCategory(value)
  }

  const handleChange = item => {
    setCategory(item.value)
  }

  const getCategoryList = () => {
    const defaultValue = { value: "all", label: "Tout" }

    const list = dataMembres.map((item, index) => {
      return { value: item.categorie.text, label: item.categorie.text }
    })

    list.unshift(defaultValue)

    const uniq = {}
    return list.filter(item => !uniq[item.value] && (uniq[item.value] = true))
  }

  const memberList = () => {
    if (category === "all") {
      return dataMembres
    } else {
      return dataMembres.filter(item => item.categorie.text === category)
    }
  }

  return (
    <Section color={colorSection} id="les-membres">
      <Container>
        <WrapperContent>
          <Title as="h2">{title}</Title>
        </WrapperContent>
      </Container>
      <div>
        <DeviceContext.Consumer>
          {deviceValues => {
            return deviceValues.width ? (
              deviceValues.isMobile ? (
                <WrapperSelect>
                  <Select
                    dataSelect={getCategoryList()}
                    value={
                      getCategoryList().filter(
                        item => item.value === category
                      )[0]
                    }
                    handleChange={value => handleChange(value)}
                    fullWidth
                    isSearchable={false}
                    isMulti={false}
                  />
                </WrapperSelect>
              ) : (
                <Filtre>
                  {getCategoryList().map((item, index) => (
                    <Category
                      key={index}
                      onClick={() => handleClick(item.value)}
                      className={
                        "sector-style" +
                        (item.value === category ? " isActive" : " ")
                      }
                    >
                      {item.label}
                    </Category>
                  ))}
                </Filtre>
              )
            ) : null
          }}
        </DeviceContext.Consumer>
      </div>
      <WrapperItem>
        {memberList().map((item, index) => (
          <CardMembre
            key={index}
            title={item.nom.text}
            sector={item.categorie.text}
            text={item.description.text}
            logo={item.image.fluid}
            link={item.lien.url}
          />
        ))}

        <CardMembre
          isInscriptionCard={true}
          textInscription={lastCardText}
          logo={lastCardImage}
          buttonText={lastCardButton}
        />
      </WrapperItem>
    </Section>
  )
}

export default LesMembres
