import React, { useState, useEffect } from "react"
import styled, { css } from "styled-components"
import AnchorLink from "react-anchor-link-smooth-scroll"

import ArrowIcon from "../../../images/arrow.png"
import globalVariables from "../../globalVariables"
import { DeviceContext } from "../DeviceProvider"

const getAnchorPosition = props => {
  let top
  let bottom

  if (props.position === "top") {
    top = "-28px"
    bottom = "unset"
  }

  if (props.position === "bottom") {
    top = "unset"
    bottom = "-28px"
  }

  if (!props.border && props.position === "top") {
    top = "-28px"
    bottom = "unset"
  }

  if (!props.border && props.position === "bottom") {
    top = "unset"
    bottom = "-41px"
  }

  return css`
    top: ${top};
    bottom: ${bottom};
  `
}
const AnchorStyled = styled(AnchorLink)`
  background: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-tap-highlight-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  cursor: pointer;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  height: 0;
  ${getAnchorPosition}

  &::before {
    content: "";
    position: absolute;
    bottom: ${props => (props.position === "top" ? "unset" : "-24px")};
    top: ${props => (props.position === "top" ? "-24px" : "unset")};
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: ${props => (props.isborder === "true" ? "104px" : "0")};
    height: ${props => (props.isborder === "true" ? "104px" : "0")};
    background-color: ${props =>
      props.isborder === "true" ? props.theme.white : null};
    z-index: -1;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    img {
      width: 65px;
    }
    &::before {
      bottom: ${props => (props.position === "top" ? "unset" : "-16px")};
      top: ${props => (props.position === "top" ? "-16px" : "unset")};
      width: ${props => (props.isborder === "true" ? "84px" : "0")};
      height: ${props => (props.isborder === "true" ? "84px" : "0")};
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    bottom: -48px;
    img {
      width: auto;
    }
    &::before {
      bottom: ${props => (props.position === "top" ? "unset" : "-24px")};
      top: ${props => (props.position === "top" ? "-24px" : "unset")};
      width: ${props => (props.isborder === "true" ? "104px" : "0")};
      height: ${props => (props.isborder === "true" ? "104px" : "0")};
    }
  }
`

const Anchor = ({ border, anchor, position, HiddenOnMobile }) => {
  // the arrow anchor is not displayed on mobile only if the parent has a
  // props "HiddenOnMobile"

  const condition = isMobile => {
    return (
      (isMobile && !HiddenOnMobile) ||
      (!isMobile && !HiddenOnMobile) ||
      (!isMobile && HiddenOnMobile)
    )
  }

  const [isWrapperLogoHidden, setIsWrapperLogoHidden] = useState(false)

  const handleScroll = () => {
    const currentScrollY = window.scrollY
    const menuTopPosition = document.getElementById("menuTopPosition").offsetTop

    if (currentScrollY > menuTopPosition) {
      setIsWrapperLogoHidden(true)
    } else {
      setIsWrapperLogoHidden(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const getOffset = windowWidth => {
    if (windowWidth < 768) {
      return 130
    } else if (windowWidth < 1441) {
      return isWrapperLogoHidden ? 56 : 176
    } else {
      return isWrapperLogoHidden ? 80 : 320
    }
  }

  return (
    <DeviceContext.Consumer>
      {deviceValues => {
        return deviceValues.width
          ? condition(deviceValues.isMobile) && (
              <AnchorStyled
                href={"#" + anchor}
                offset={getOffset(deviceValues.windowWidth)}
                isborder={border}
                position={position}
                className="anchor-style"
              >
                <img src={ArrowIcon} alt="ancre collectif" />
              </AnchorStyled>
            )
          : null
      }}
    </DeviceContext.Consumer>
  )
}

export default Anchor
