export const MONTHS = [
  "Janvier",
  "Février",
  "Mars",
  "Avril",
  "Mai",
  "Juin",
  "Juillet",
  "Août",
  "Septembre",
  "Octobre",
  "Novembre",
  "Décembre",
]
export const WEEKDAYS_LONG = [
  "Dimanche",
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
]
export const WEEKDAYS_SHORT = ["Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa"]

export const maritalStatusOptions = [
  { value: "Célibataire", label: "Célibataire" },
  { value: "Marié", label: "Marié" },
  { value: "Pacsé ou en concubinage", label: "Pacsé ou en concubinage" },
  { value: "Séparé", label: "Séparé" },
  { value: "Divorcé", label: "Divorcé" },
  { value: "Veuf", label: "Veuf" },
]

export const childrenOptions = [
  { value: "0 enfant", label: "0 enfant" },
  { value: "1 enfant", label: "1 enfant" },
  { value: "2 enfants", label: "2 enfants" },
  { value: "3 enfants", label: "3 enfants" },
  { value: "4 enfants", label: "4 enfants" },
  { value: "+ de 5 enfants", label: "+ de 5 enfants" },
]

export const careerOptions = [
  { value: "Salarié", label: "Salarié" },
  { value: "Demandeur d'emploi", label: "Demandeur d'emploi" },
  { value: "Commerçant, artisan", label: "Commerçant, artisan" },
  { value: "Artiste", label: "Artiste" },
  {
    value: "Vous exercez une profession libérale",
    label: "Vous exercez une profession libérale",
  },
  { value: "Étudiant", label: "Étudiant" },
  { value: "Retraité", label: "Retraité" },
]

export const commitmentOptions = [
  { value: "Réduire mes déchets", label: "Réduire mes déchets" },
  { value: "Consommation solidaire", label: "Consommation solidaire" },
  { value: "Anti-gaspillage", label: "Anti-gaspillage" },
  {
    value: "Up-cycling (fabrication de produit à partir de matériaux recyclés)",
    label: "Up-cycling (fabrication de produit à partir de matériaux recyclés)",
  },
  { value: "Bio", label: "Bio" },
  {
    value: "Consommation en circuit court",
    label: "Consommation en circuit court",
  },
  { value: "Insertion par le travail", label: "Insertion par le travail" },
  {
    value: "Préservation du savoir-faire ou de l'artisanat",
    label: "Préservation du savoir-faire ou de l'artisanat",
  },
  {
    value: "Préservation de l'environnement ",
    label: "Préservation de l'environnement ",
  },
  { value: "Fabriquer moi-même", label: "Fabriquer moi-même" },
  { value: "Autre", label: "Autre" },
]

export const mailOptions = [
  { value: "1 à 5 enquêtes par an", label: "1 à 5 enquêtes par an" },
  { value: " + de 5 enquêtes par an ", label: " + de 5 enquêtes par an " },
  { value: " + de 10 enquêtes par an ", label: " + de 10 enquêtes par an " },
]
