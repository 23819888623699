import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

import Container from "../elements/Container"
import ItemInfographie from "./item"
import FlecheBleu from "../../images/fleche.png"
// import FlecheDore from "../../images/FlecheH.png"
import globalVariables from "../globalVariables"

const WrapperItem = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
`

// const Item = styled.div`
//   flex: 1 1 32%;
//   display: flex;
//   justify-content: center;
//   @media (min-width: ${globalVariables.minDesktop}) {
//     flex: 1 1 33%;
//   }
// `

const WrapperCoeur = styled.div`
  /* width: 210px; */
  height: 210px;
  position: relative;
  text-align: center;

  @media (min-width: ${globalVariables.minTablet}) {
    /* width: 180px; */
    width: 80px;
    height: 180px;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      /* left: 30%; */
      left: 50%;
      height: 60px;
      width: 55px;
      transform: rotate(142deg) translateX(50%);
      background: url(${FlecheBleu});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    /* width: 210px; */
    height: 210px;
    &::before {
      height: 60px;
      width: 75px;
    }
  }
`

// const WrapperFlecheDore = styled.div`
//   position: absolute;
//   transform: ${props => props.transform};
//   background: url(${FlecheDore});
//   background-size: contain;
//   background-repeat: no-repeat;
//   background-position: center;
//   @media (min-width: ${globalVariables.minTablet}) {
//     width: 22px;
//     height: 20px;
//     top: ${props => (props.top === "-30%" ? "-20%" : props.top)};
//     bottom: ${props => (props.bottom === "-30%" ? "-20%" : props.bottom)};
//     left: ${props => (props.left === "-30%" ? "-20%" : props.left)};
//     right: ${props => (props.right === "-30%" ? "-20%" : props.right)};
//   }
//   @media (min-width: ${globalVariables.minDesktop}) {
//     height: 20px;
//     top: ${props => props.top};
//     bottom: ${props => props.bottom};
//     left: ${props => props.left};
//     right: ${props => props.right};
//   }
// `

// const CoeurTexte = styled.h4`
//   position: absolute;
//   top: 40%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   width: 100%;
//   height: 100%;
//   margin: 0;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   line-height: 1.4;
// `

const InfographieDesktop = ({ coeur, ellipse, comment }) => {
  return (
    <Container>
      <WrapperItem>
        <ItemInfographie
          title={comment.titre1.text}
          description={comment.contenu1.text}
          image={comment.picto1.fluid}
          ellipse={ellipse}
        />
        <ItemInfographie
          title={comment.titre2.text}
          description={comment.contenu2.text}
          image={comment.picto2.fluid}
          ellipse={ellipse}
        />
        {/* <Item> */}
        <WrapperCoeur>
          {/* <Img fluid={coeur} />
            <WrapperFlecheDore
              top="-30%"
              bottom="unset"
              left="50%"
              right="unset"
              transform="translateX(-50%)"
            />
            <WrapperFlecheDore
              top="50%"
              bottom="unset"
              left="-30%"
              right="unset"
              transform="translateY(-50%) rotate(-90deg)"
            />
            <WrapperFlecheDore
              top="50%"
              bottom="unset"
              right="-30%"
              unset="unset"
              transform="translateY(-50%) rotate(90deg)"
            />
            <CoeurTexte>{comment.coeur[0].text}</CoeurTexte> */}
        </WrapperCoeur>
        {/* </Item> */}

        <ItemInfographie
          title={comment.titre3.text}
          description={comment.contenu3.text}
          image={comment.picto3.fluid}
          ellipse={ellipse}
        />
      </WrapperItem>
    </Container>
  )
}

export default InfographieDesktop
