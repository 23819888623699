// Select component documentation : https://react-select.com/home

import React from "react"
import Select from "react-select"
import theme from "../theme"
import { redirectTo } from "@reach/router"

const customStyles = {
  option: (provided, { isHover, isFocused, isSelected }) => ({
    ...provided,
    color: theme.white,
    background:
      isHover || isFocused || isSelected ? theme.primaryDark : theme.primary,
    fontFamily: "SourceSerifPro",
    cursor: "pointer",
  }),
  singleValue: provided => ({
    ...provided,
    fontFamily: "SourceSerifPro",
    color: theme.black,
    background: theme.white,
  }),
  placeholder: provided => ({
    ...provided,
    fontFamily: "SourceSerifPro",
    color: "#A2A2A2",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "93%",
  }),
  valueContainer: provided => ({
    ...provided,
    background: theme.white,
    color: theme.primary,
  }),
  control: (provided, state) => ({
    ...provided,
    background: theme.white,
    color: theme.primary,
    border: "none",
    borderBottom: "2px solid",
    borderColor: theme.primary,
    cursor: "pointer",
    boxShadow: "none",
  }),
  multiValue: (provided, state) => ({
    ...provided,
    background: theme.primary,
  }),
  multiValueLabel: (provided, state) => ({
    ...provided,
    color: theme.white,
  }),
  multiValueRemove: (provided, state) => ({
    ...provided,
    color: theme.white,
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    background: theme.white,
    color: theme.primary,
  }),
  dropdownIndicator: (provided, { isHover }) => ({
    ...provided,
    color: isHover ? theme.primary : theme.primary,
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: theme.primary,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    background: theme.primary,
  }),
}

export const StyledSelect = ({
  dataSelect,
  isDisabled,
  isLoading,
  isClearable,
  isRtl,
  isSearchable,
  name,
  isMulti,
  placeholder,
  value,
  className,
  handleChange,
}) => {
  return (
    <Select
      // defaultValue={dataSelect[0]}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={isRtl}
      isSearchable={isSearchable}
      name={name}
      options={dataSelect}
      isMulti={isMulti}
      placeholder={placeholder}
      value={value}
      onChange={handleChange}
      styles={customStyles}
      className={className}
      theme={selectTheme => ({
        ...selectTheme,
        borderRadius: 0,
        colors: {
          ...selectTheme.colors,
          neutral0: theme.primary,
          neutral10: theme.primary,
          neutral15: theme.primary,
          neutral20: theme.primary,
          neutral30: theme.primaryDark,
          neutral40: theme.primary,
          neutral60: theme.primary,
          neutral70: theme.primary,
          neutral80: theme.primary,
          neutral90: theme.white,
          primary25: theme.primaryDark,
          primary50: theme.primaryDark,
          primary60: theme.primaryDark,
          primary75: theme.primaryDark,
          // primary: theme.white,
          primary: theme.primaryDark,
        },
      })}
    />
  )
}
