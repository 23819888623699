import FormValidator from "../elements/FormValidator"

export const validator = new FormValidator([
  {
    field: "firstName",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre prénom",
  },
  {
    field: "lastName",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre nom",
  },
  {
    field: "email",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner votre e-mail",
  },
  {
    field: "email",
    method: "isEmail",
    validWhen: true,
    message: "Cet e-mail n'est pas valide",
  },
  {
    field: "companyName",
    method: "isEmpty",
    validWhen: false,
    message: "Veuillez renseigner le nom de votre société",
  },
])
