import React from "react"
import styled from "styled-components"

import globalVariables from "../../globalVariables"

const SectionStyled = styled.section`
  position: relative;
  padding: ${props => (props.padding ? props.padding : "50px 0")};
  background-color: ${props =>
    props.color ? props.theme[props.color] : "inherit"};

  @media (min-width: ${globalVariables.minTablet}) {
    padding: ${props => (props.padding ? props.padding : "100px 0")};
  }
`

const Section = ({ color, padding, children, ...props }) => {
  return (
    <SectionStyled color={color} padding={padding} {...props}>
      {children}
    </SectionStyled>
  )
}

export default Section
