import React, { useState } from "react"
import styled from "styled-components"

import CallToAction from "./CallToAction"
import ReassuranceBloc from "./ReassuranceBloc"
import FormEntreprise from "./FormEntreprise"
import FormParticulier from "./FormParticulier"
import globalVariables from "../globalVariables"

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 150px;
  & > div {
    flex: 1 1 50%;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    min-height: 390px;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    min-height: 530px;
  }
`

const Inscription = ({ reseau, data }) => {
  const [content, setContent] = useState("entreprise")
  const handleClick = value => setContent(value)

  return (
    <React.Fragment>
      <section id="inscription">
        <Wrapper>
          <CallToAction
            title={data.entreprise.titre.text}
            description={data.entreprise.contenu.text}
            buttonText={data.entreprise.bouton.text}
            isActive={content === "entreprise"}
            colorBg="primaryDark"
            handleClick={() => handleClick("entreprise")}
          />
          {/* <CallToAction
            title={data.particulier.titre.text}
            description={data.particulier.contenu.text}
            buttonText={data.particulier.bouton.text}
            isActive={content === "particulier"}
            colorBg="primary"
            handleClick={() => handleClick("particulier")}
          /> */}
        </Wrapper>
      </section>
      {content === "entreprise" && (
        <React.Fragment>
          <ReassuranceBloc
            id="reassurance"
            title={data.entreprise.titre_reassurance.text}
            text={data.entreprise.contenu_reassurance.html}
            reseau={reseau}
            zindex="-1"
            items={data.entreprise.items}
          />
          <FormEntreprise data={data.entreprise} />
        </React.Fragment>
      )}
      {content === "particulier" && (
        <React.Fragment>
          <ReassuranceBloc
            id="reassurance"
            title={data.particulier.titre_reassurance.text}
            text={data.particulier.contenu_reassurance.html}
            reseau={reseau}
            zindex="-1"
          />
          <FormParticulier data={data.particulier} />
        </React.Fragment>
      )}
    </React.Fragment>
  )
}

export default Inscription
