import React, { useState } from "react"
import styled from "styled-components"
import addToMailchimp from "gatsby-plugin-mailchimp"
import isEmail from "validator/lib/isEmail"
import axios from 'axios'

import Title from "../elements/Title"
import Button from "../elements/Button"
import Input from "../elements/Input"
import globalVariables from "../globalVariables"
import Facebook from "../../images/facebook.png"
import Linkedin from "../../images/linkedin.png"
import Twitter from "../../images/twitter.png"
import Instagram from "../../images/instagram.png"

const Container = styled.section`
  display: flex;
  flex-direction: column;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-direction: row;
  }
`

const Wrapper = styled.div`
  flex: 0 0 50%;
  background: ${props => props.theme[props.colorBg]};
  transition: all 0.2s ease-in-out;
  padding: 2rem 1rem;
  p {
    margin-top: 3rem;
  }
  @media (min-width: ${globalVariables.minTablet}) {
    padding: 3rem 2rem 2rem;
    /* p {
      max-width: 400px;
    } */
  }
  @media (min-width: ${globalVariables.minDesktop}) {
    padding: 3rem 6rem 2rem;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    padding: 5rem 12rem;
    p {
      margin-top: 4rem;
      /* max-width: 500px; */
    }
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: ${globalVariables.minTablet}) {
    flex-wrap: nowrap;
  }
  button span {
    color: ${props => props.theme.primary};
  }
`

const Spacer = styled.div`
  margin-right: 1rem;
  margin-bottom: 1rem;
  width: 100%;
  position: relative;
  @media (min-width: ${globalVariables.minTablet}) {
    margin-bottom: 0;
  }
  @media (min-width: ${globalVariables.medDesktop}) {
    width: 50%;
  }
`

const List = styled.ul`
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem 0;
`
const WrapperImage = styled.li`
  list-style: none;
  margin: 0 1rem;
  position: relative;
  width: 32px;
  height: 32px;
  img {
    width: 100%;
    height: 100%;
  }
  &:first-child {
    margin-left: 0;
  }
  &:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 50%;
    right: calc(-1rem - 1px);
    transform: translateY(-50%);
    height: 12px;
    width: 2px;
    background: ${props => props.theme.white};
  }
`

const Text = styled.p`
  min-height: 72px;
`

const ValidationMessage = styled.span`
  position: absolute;
  bottom: -30px;
  left: 0;
  font-size: 90%;
  color: ${props => props.theme[props.color]};
`

const ContactNewsletter = ({ contact, newsletter }) => {
  const [email, setEmail] = useState({
    email: "",
    submit: false,
    sendToMailchimp: false,
  })

  const updateInputData = event => {
    setEmail({ ...email, email: event.target.value })
  }

  const handleSubmit = event => {
    event.preventDefault()
    // on verifie que le format e-mail est valide
    if (isEmail(email.email)) {
      /*
      addToMailchimp(email.email)
        .then(data => {
          // I recommend setting data to React state
          setEmail({ email: "", sendToMailchimp: true })
        })
        .catch(() => {
          // unnecessary because Mailchimp only ever
          // returns a 200 status code
          // see below for how to handle errors
        })
        */
       axios
      .post(
        "https://pa3k5wjlqh.execute-api.eu-west-3.amazonaws.com/api/contact",
        {
          "email": email.email,
        }
      )
      .then(result => {
        console.log(result)
        setEmail({ email: ""})
      });
    } else {
      setEmail({ ...email, submit: true })
    }
  }
  // console.log(email)
  return (
    <Container>
      <Wrapper colorBg="greyLight">
        <Title as="h2" position="left">
          {newsletter.titre.text}
        </Title>
        <Text>{newsletter.contenu.text}</Text>
        <Form onSubmit={handleSubmit}>
          <Spacer>
            <Input
              type="text"
              placeholder="Email"
              value={email.email}
              handleChange={e => updateInputData(e)}
              name="email"
              required
              //   fullWidth
              id="email"
            />
            {!isEmail(email.email) && email.submit && (
              <ValidationMessage color="error">
                Veuillez rentrer un e-mail valide.
              </ValidationMessage>
            )}
            {email.sendToMailchimp && (
              <ValidationMessage color="secondary">
                Merci, votre e-mail a bien été enregistré !
              </ValidationMessage>
            )}
          </Spacer>
          <Button
            title={newsletter.bouton.text}
            type="submit"
            colors="secondary"
            nomargin
          />
        </Form>
      </Wrapper>
      <Wrapper colorBg="greyDarker">
        <Title as="h2" position="left">
          {contact.titre.text}
        </Title>
        <Text>{contact.contenu.text}</Text>
        <List>
          {contact.lien_twitter && contact.lien_twitter.url.length > 0 && (
            <WrapperImage>
              <a
                href={contact.lien_twitter.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Twitter} alt="twitter icone" />
              </a>
            </WrapperImage>
          )}
          {contact.lien_facebook && contact.lien_facebook.url.length > 0 && (
            <WrapperImage>
              <a
                href={contact.lien_facebook.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Facebook} alt="facebook icone" />
              </a>
            </WrapperImage>
          )}
          {contact.lien_linkedin && contact.lien_linkedin.url.length > 0 && (
            <WrapperImage>
              <a
                href={contact.lien_linkedin.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Linkedin} alt="linkedin icone" />
              </a>
            </WrapperImage>
          )}
          {contact.lien_instagram && contact.lien_instagram.url.length > 0 && (
            <WrapperImage>
              <a
                href={contact.lien_instagram.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={Instagram} alt="instagram icone" />
              </a>
            </WrapperImage>
          )}
        </List>
      </Wrapper>
    </Container>
  )
}

export default ContactNewsletter
